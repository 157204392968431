<template>
  <section class="space-y-10">
    <quick-info />
    <asom-alert
      class="mb-4"
      v-if="error"
      variant="error"
      :error-message="error"
    />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-card v-else class="print-content">
      <div class="space-y-4">
        <asom-grid :sm="1" :md="3" :gap="2">
          <asom-form-field label="Reference No" class="col-span-2">
            <p class="mt-0 font-semibold">{{get(form, 'referenceNo')}}</p>
          </asom-form-field>
          <asom-form-field label="Date" class="col-span-2">
            <p class="mt-0 font-semibold">{{displayUtcDateTime(get(form, 'dateCreated'))}}</p>
          </asom-form-field>
          <asom-form-field label="Requestor">
            <p class="font-semibold">{{get(form, 'requestorStation')}}</p>
          </asom-form-field>
          <asom-form-field label="Donor">
            <p class="font-semibold">{{get(form, 'donorStation')}}</p>
          </asom-form-field>
          <asom-form-field label="Cash Order">
            <p class="font-semibold">{{get(form, 'cashOrderReferenceNo')}}</p>
          </asom-form-field>
          <asom-form-field label="Cash Order Status">
            <p class="font-semibold">{{get(form, 'cashOrderStatus')}}</p>
          </asom-form-field>
          <asom-form-field label="Cash Bags" class="col-span-3">
            <asom-client-table
              :data="get(form, 'cashBagNoList', [])"
              :columns="['no', 'bagNo', 'isPickedUp']"
              :filterable="false"
              :searchable="false"
              :pagination="false"
            >
              <template v-slot:header_isPickedUp>Status</template>
              <template v-slot:no="{rowIndex}">{{rowIndex + 1}}</template>
              <template v-slot:isPickedUp="{data}">{{data ? 'Received' : 'Pending Moving In'}}</template>
            </asom-client-table>
          </asom-form-field>
          <div class="no-print">
            <asom-form-field label="Staff (Donor)">
              <p class="font-semibold">{{get(form, 'donorOfficerName')}}</p>
              <asom-signature-view :file-id="get(form, 'donorSignatureId')" />
            </asom-form-field>
          </div>
          <div class="no-print">
            <asom-form-field label="Staff (Recipient)">
              <p class="font-semibold">{{get(form, 'recipientOfficerName')}}</p>
              <asom-signature-view :file-id="get(form, 'recipientSignatureId')" />
            </asom-form-field>
          </div>
        </asom-grid>
        <!-- Signature Layout for Print Preview -->
        <div class="flex justify-between print-only">
          <asom-form-field label="Staff (Donor)">
            <p class="font-semibold">{{get(form, 'donorOfficerName')}}</p>
            <asom-signature-view :file-id="get(form, 'donorSignatureId')" />
          </asom-form-field>
          <asom-form-field label="Staff (Recipient)">
            <p class="font-semibold">{{get(form, 'recipientOfficerName')}}</p>
            <asom-signature-view :file-id="get(form, 'recipientSignatureId')" />
          </asom-form-field>
        </div>
        <div class="col-span-2 flex justify-end space-x-4 no-print">
          <asom-button
            text="Back"
            variant="secondary"
            @click="$router.push({ name: 'Station Transfer Main' })"
          />
          <asom-button
            text="Print"
            icon="printer"
            variant="primary"
            @click="printCDF"
          />
        </div>
      </div>
    </asom-card>
  </section>
</template>
<script>
import get from 'lodash.get'
import { displayUtcDateTime } from '@/helpers/dateTimeHelpers'
import { getStationTransferDetails } from '../../../../services/cashManagement.service';
import QuickInfo from "../../_QuickInfo.vue";

export default {
  components: {
    QuickInfo
  },
  data() {
    return {
      error: null,
      isLoading: false,
      form: null
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    get,
    displayUtcDateTime,
    async loadData() {
      this.error = null;
      this.isLoading = true;
      const id = get(this.$route, 'query.id');
      const result = await getStationTransferDetails(id);
      if (result.success) {
        this.form = get(result, 'payload.data', {});
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    printCDF() {
      window.print();
    }
  }
}
</script>
<style scoped>
  .print-only {
    display: none;
  }
  
  @media print {
    .print-content {
      width: 100%;
    }

    .print-only {
      display: flex;
    }

    .no-print {
      display: none;
    }
  }

  @page {
      size: portrait;
  }
</style>